<template>
   <a-row :gutter="25">
    <a-col 
      :xxl="24" 
      :lg="24" 
      :md="24"
      :xs="24">
      <a-spin size="medium" style="position:relative; left: 50%;margin-top: 14rem;"/>
    </a-col>
   </a-row>
</template>

<script>

import { useRouter } from "vue-router";
import { reactive } from 'vue';
import { getItem, setItem, removeItem } from '@/utility/localStorageControl';
import { useStore } from 'vuex';
import { authWithSsid } from '@/services/auth';

export default {
  name: 'Login',
  components: {
  },
  async setup() {
    const { dispatch } = useStore();
    dispatch('changeTitleHeader', "");

    const getDatalogin = () => {
      const router = useRouter();
      var ssidRoute = router.currentRoute.value.query.ssid;
      var ssidSessionStorage = getItem('ssid');
      //let dominio = window.location.hostname; //temp line, please remove

      if(ssidRoute == undefined) {
        if(ssidSessionStorage == undefined){
          location.replace("https://mi.sej.jalisco.gob.mx?servicio=" + process.env.VUE_APP_BASE_URL_FRONTEND);
        } else {
          ssidRoute = ssidSessionStorage;
        }
      } else {
        removeItem('ssid');
        removeItem("userData");
        removeItem("token");
        removeItem("roles");

        const data = {
          token: ssidRoute
        }
        authWithSsid(data).then((response) => {
          if(response.is_ok){
            setItem('ssid', ssidRoute);
            setItem("userData", response.data.user);
            setItem("token", response.data.token.access_token);
            setItem("roles", response.data.roles);
            dispatch('changeCCT', response.data.allWorkCenters);
            dispatch('changeAdministrativeAreas', response.data.allAdministrativeArea);
            location.reload();
            //this is removed because de host is diffrent
            /*if(dominio != "localhost" && dominio != "eventos.azit.mx" && ssidRoute != undefined){//redirect by test https, please remove
              //alert("redireccion por https");
              location.replace("https://eventos.azit.mx?ssid=" + ssidRoute);
            } else {
              location.reload();
            }*/
          }
        }).catch(() => {
          location.replace("https://mi.sej.jalisco.gob.mx?servicio=" + process.env.VUE_APP_BASE_URL_FRONTEND);
        })
      }
    }
    
    const dataView = reactive({
      ssid: getDatalogin()
    })

    return {
      dataView,
      getDatalogin
    };
  },
};
</script>
<style scoped>
</style>
