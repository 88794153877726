import { sendRequest } from "../utility/utility";
import { getItem } from "../utility/localStorageControl";

const authWithSsid = async (ssid) => {
    const route = "access/signIn";
    const request = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        body: JSON.stringify(ssid)
    };
    return await sendRequest(route, request);
}

const signOutSsid = async () => {
    const route = "access/signOut";
    const token_ssid = getItem("ssid");
    const request = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            auth: `${token_ssid}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

export {
    authWithSsid,
    signOutSsid
}